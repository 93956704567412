import { template as template_6f47f26f359c4fcc8ff823978799a32e } from "@ember/template-compiler";
const FKText = template_6f47f26f359c4fcc8ff823978799a32e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
