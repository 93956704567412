import { template as template_8c2980ca0762405c95243e6eccb8e6dc } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_8c2980ca0762405c95243e6eccb8e6dc(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
